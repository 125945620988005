import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Peni from "../../assets/peni.png";
import { useNavigate } from "react-router-dom";
import Feild2 from "../../assets/feild2.png";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import dayjs, { Dayjs } from 'dayjs';
import { GifBoxOutlined, Sms, Wallet, EmailOutlined } from '@mui/icons-material';

function disableWeekends(datet) {
 // var date = new Date(datet);
  
  return (datet.getDay()==1 || datet.getDay()==2 || datet.getDay()==3)?true:false;
}

export default function Index({ wallett, setWallett, walletAddress, loading }) {
  let navigate = useNavigate();
  const [date, setDate] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [gift, setGift] = React.useState(false);
  const [giftsent, setGiftsent] = React.useState(false);  

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

const handleOpenGift = () => {
    setOpen3(false);
    setGift(true);
  };
  const handleOpenGiftsent = () => {
    navigate("/gift-thanks/Wake Field", { replace: true });
    setOpen(false);
    setGift(false);
    setGiftsent(true);
  };  
  const handleCloseGift = () => {
    setGift(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
  };  
  const handleNavigateAccount = (e) => {
    e.preventDefault();

    navigate("/dashboard", { replace: true });
  };

  const handleClick = () => {
    setOpen(true);
    setBack(true);

    setOpen2(true);
    setOpen(false);
    setBack(true);
  };

  const handleClick3 = () => {
    setOpen3(true);
    setOpen2(false);
    setBack(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
    setBack(false);
  };

  const handleClose3 = () => {
    setOpen2(false);
    setOpen(false);
    setOpen3(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/vitals");
  };

  const getWalletMessage = () => {
    if (loading) {
      return "Loading Wallet...";
    }
    setWallett(walletAddress);

    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };
  const handlerange = (ranges) =>{
    console.log(ranges.selection);

    setRange([ranges.selection])
  }

  return (
    <>
      <div className="nft_market">
        <div className="d-flex justify-content-between">
          <Link to="/redeem-nft">
            <a>
              <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
            </a>
          </Link>
          <div className="dropdown">
            <Button
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                borderRadius: "40px",
                padding: "10px",
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: "13px",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
            >
              {wallett ? <>Welcome{getWalletMessage()}</> : null}
            </Button>
          </div>
        </div>

        <main className="nft_market_ p-0">
          <div className="container pt-5">
            <div className="row">
              <div className="col-12 p-0">
                <h1
                  className="text-center d-block"
                  style={{ fontWeight: "lighter" }}
                >
                  REDEEM RETREAT
                </h1>
                <h2 className="text-center d-block">
                  Select Date
                </h2>
              </div>
              <div className="col-12 main-info p-0">
                <img src={Feild2} alt="" className="img-fluid" />
              </div>
              <div className="col-12 main-info p-0 pt-3">
              <DateRange
  editableDateInputs={true}
  onChange={handlerange}
  moveRangeOnFirstSelection={false}
  ranges={range}
  minDate={new Date()}
  disabledDay={disableWeekends}
/>
              </div>
              <div className="col-12 main-info p-30">
           
              </div>
              <div className="col-12 main-info p-30">
                <button
                  onClick={handleClick}
                  className="redeemBtn mt-4 showselectorder"
                >
                  Redeem Retreat
                </button>
              </div>
            </div>
          </div>
        </main>
        <div className={open2 ? "orderbox" : "orderbox2"}>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "right",
            }}
          >
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose2} />
          </div>

          <div>
            <button className="btn-close" />
            <div className="confirmpopup-content">
              <div className="orderbox-content">
                <p>Retreat Selected</p>
                <h1 className="d-block">WAKE FIELD</h1>
                <p>Date(s) Selected</p>
                <h1 className="d-block dselected">{dayjs(range[0].startDate).format('DD/MM/YYYY')} - {dayjs(range[0].endDate).format('DD/MM/YYYY')}</h1>
              </div>
            </div>
            <div className="confirmpopup-bottom">
              <a onClick={handleClick3} className="redeemBtn mt-3 showconfirm">
                Confirm Retreat
              </a>
            </div>
          </div>
        </div>

        <div className={open3 ? "confirmpopup" : "confirmpopup2"}>
          <div
            style={{
              paddingTop: "5px",

              textAlign: "right",
            }}
          >
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose3} />
          </div>
          <div>
            <div className="confirmpopup-content">
              <h1 className="text-center d-block">THANK YOU</h1>
              <p>
                Your retreat is booked. You will recieve an email with further
                details.
              </p>
            </div>
            <div className="confirmpopup-bottom">
              <a onClick={(e) => handleNavigate(e)} className="redeemBtn mt-3">
                View Vitals
              </a>
              <p style={{textAlign:"center", padding:"5px", margin:"10px"}}>or</p>
            <a onClick={handleOpenGift} className="mt-3 giftbtn">
              Send it as a gift to someone
            </a>
            </div>
          </div>
        </div>

        <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="text" placeholder='Enter Email'/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="text" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your retreat is sent as a gift.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
        </div>
        <div className={open ? "overloay1" : "overloay2"}></div>
        <div className={open2 ? "overloay1" : "overloay2"}></div>
        <div className={open3 ? "overloay1" : "overloay2"}></div>
        <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
