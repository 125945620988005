import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import Redemption1 from "../../components/redemption1";
import Redemption2 from "../../components/redemption2";
import Redemption3 from "../../components/redemption3";
import { useParams } from "react-router-dom";
import axiosClient from "../../helper";
import Myaccount from "../../components/myaccount";

export default function Index({  connectWalletPressed, wallett, setWallett, walletAddress, loading }) {
  const { component } = useParams();
  const [product, setProduct] = useState(false);
  const [productloading, setLoading] = useState(false);

  const getWalletMessage = () => {
    if (loading) {
      return "Loading Wallet...";
    }
    setWallett(walletAddress);

    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };
  const getProduct = (component) => {
   /*  */
    axiosClient()
    .get(`/wp/v2/redemption/?slug=`+component)
    .then((res) => {
      setProduct(res.data[0]);
 
    })
    .catch(() => {
      setLoading(false);
    });
  }
  return (
    <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/redeem-nft">
          <a>
          <ArrowBackIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        </Link>
        <Myaccount wallett={wallett} setWallett={setWallett} walletAddress={walletAddress} loading={loading} connectWalletPressed={connectWalletPressed} />
       
      </div>
      {getProduct(component)}
      {productloading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ):(<>
         
            {product && product.acf.type === "1" ? <Redemption1 item={product} /> : null}
            {product && product.acf.type === "2" ? <Redemption2 item={product} /> : null}
            {product && product.acf.type === "3" ? <Redemption3 item={product} /> : null}

            </>)}
    </div>
  );
}
