import axios from "axios";

let BASE_URL = "https://presale.wake.net/wp-json/";

export default function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };

  let instance = axios.create(defaultOptions);
  instance.interceptors.request.use(function (config) {
    return config;
  });
  return instance;
}
