import React, { useState } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Metamask from "../../assets/metamask.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Slider from "react-slick";
import Myaccount from "../../components/myaccount";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Dashboard({  wallett, setWallett, walletAddress, loading }) {
  const [open, setOpen] = useState(false);
  const [back, setBack] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode:true,
    centerPadding:"0px",
    arrows:false,
    focusOnSelect:true
  }  
  let navigate = useNavigate();
  const handleClick = () => {
  
    if (wallett) {
      setOpen(false);
      navigate("/redeem-nft", { replace: true });
    } else {
      setOpen(true);
      setBack(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/nft-market", { replace: true });
  };

  const handleNavigate2 = (e) => {
    e.preventDefault();
    if (wallett) {
      navigate("/redeem-nft", { replace: true });
    } else {
      navigate("/nft-market");
    }
  };
  return (
    <>
      <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/">
          <a>
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        </Link>
        <Myaccount wallett={wallett} setWallett={setWallett} walletAddress={walletAddress} loading={loading} />
      </div>
        <main className="nft_market_">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1
                  className="text-center d-block m-0"
                  style={{ fontWeight: "lighter" }}
                >
                  WELCOME NICK!
                </h1>
                <p
                  className="text-center"
                  style={{ fontWeight: "lighter", fontSize: "16px" , marginBottom:"30px"}}
                >
                  You can view your NFTs collection<br />
and keep track of your redemptions on this screen
                </p>
              <div className="collection">
                <h1
                    className="text-center d-block"
                    style={{ fontWeight: "400", fontSize:'20px' }}
                  >
                    GOLD COLLECTION (4)
                  </h1>
                  <Slider {...settings}>
          <div className="colslide">
            <img src={require("../../assets/g2.png")} />
          </div>
          <div className="colslide">
          <img src={require("../../assets/g2.png")} />
          </div>
          <div className="colslide">
          <img src={require("../../assets/g2.png")}/>
          </div>
          <div className="colslide">
          <img src={require("../../assets/g2.png")} />
          </div>
      
        </Slider>
              </div>
              <div className="collection">
                <h1
                    className="text-center d-block"
                    style={{ fontWeight: "400", fontSize:'20px' }}
                  >
                    SILVER COLLECTION (6)
                  </h1>
                  <Slider {...settings}>
          <div className="colslide">
            <img src={require("../../assets/s2.png")} />
          </div>
          <div className="colslide">
          <img src={require("../../assets/s2.png")} />
          </div>
          <div className="colslide">
          <img src={require("../../assets/s2.png")}/>
          </div>
          <div className="colslide">
          <img src={require("../../assets/s2.png")} />
          </div>
      
        </Slider>
              </div>
              <div className="collection">
                <h1
                    className="text-center d-block"
                    style={{ fontWeight: "400", fontSize:'20px' }}
                  >
                    BRONZE COLLECTION (3)
                  </h1>
                  <Slider {...settings}>
          <div className="colslide">
            <img src={require("../../assets/z2.png")} />
          </div>
          <div className="colslide">
          <img src={require("../../assets/z2.png")} />
          </div>
          <div className="colslide">
          <img src={require("../../assets/z2.png")}/>
          </div>
          <div className="colslide">
          <img src={require("../../assets/z2.png")} />
          </div>
      
        </Slider>
              </div>

              <h1
                    className="text-center d-block m-0"
                    style={{ fontWeight: "200", fontSize:'20px' }}
                  >
                    REDEMPTIONS
                  </h1>
                  <p className="m-0">Available Redemptions (12)</p>
                  <p className="m-0">Redeemed Redemptions (8)</p>
                

                <div className="redemptions">
                  <div className="redemption">
                    <div className="redemptionname">Property buy out</div>
                    <div className="redemptiondetail">
                      <div className="redemptionleft">
                      5 Days clinical retreat
                      <span>(up to 12 pax available)</span>
                      </div>
                      <div className="redemptionright">
            <p>available (10)</p>
            <p>redeemed (2)</p>
                      </div>
                    </div>
                  </div>
                  <div className="redemption">
                    <div className="redemptionname">VirtuaL COACHING</div>
                    <div className="redemptiondetail">
                      <div className="redemptionleft">
                      50 min session each
                      <span>(6 per guest)</span>
                      </div>
                      <div className="redemptionright">
            <p>available (10)</p>
            <p>redeemed (2)</p>
                      </div>
                    </div>
                  </div>
                  <div className="redemption">
                    <div className="redemptionname">WAKE KIT</div>
                    <div className="redemptiondetail">
                      <div className="redemptionleft">
                      swag
                      </div>
                      <div className="redemptionright">
            <p>redeemed</p>
      
                      </div>
                    </div>
                  </div>
                  <div className="redemption">
                    <div className="redemptionname">IRL ARTWORK</div>
                    <div className="redemptiondetail">
                      <div className="redemptionleft">
                      love n peace
                      </div>
                      <div className="redemptionright">
            <p>redeemed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className={open ? "confirmpopupconnect" : "confirmpopupconnect2"}>
          <button className="btn-close" onClick={handleClose}></button>
          <div className="confirmpopup-content2">
            <img
              width="50px"
              height="40px"
              style={{ marginTop: "10px" }}
              src={Metamask}
              alt=""
            />
            <p className="metamask2">Connect Metamask Wallet</p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={(e) => handleNavigate2(e)} className="redeemBtn mt-3">
              Connect Wallet
            </a>
          </div>
        </div>
        <div className={back ? "overloay1" : "overloay2"}></div>
      </div>
    </>
  );
}
