import React, { useState } from "react";
import { Link } from "react-router-dom";
import Wake from "../../assets/wake.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { GifBoxOutlined, Sms, Wallet, EmailOutlined } from '@mui/icons-material';

export default function Index({item}) {
  const [open, setOpen] = useState(false);
  const [gift, setGift] = React.useState(false);
  const [giftsent, setGiftsent] = React.useState(false);  
  const [back, setBack] = useState(false);
  const [open3, setOpen3] = useState(false);
  let navigate = useNavigate();

  const handleClick = () => {
    setOpen(true);
    setBack(true);
  };
  const handleClick2 = () => {
    setOpen3(true);
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setBack(false);
    setOpen3(false);
  };
  const handleOpenGift = () => {
    setOpen3(false);
    setGift(true);
  };
  const handleOpenGiftsent = () => {
    navigate("/gift-thanks/"+item.title.rendered, { replace: true });    
    setOpen(false);
    setGift(false);
    setGiftsent(true);
  };  
  const handleCloseGift = () => {
    setGift(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
  };  
  const handleNavigateAccount = (e) => {
    e.preventDefault();

    navigate("/dashboard", { replace: true });
  };
  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/redeem-nft");
  };
  return (
    <>
      <main className="nft_market_" style={{ paddingBottom: "200px" }}>
        <div className="container ">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ paddingBottom: "70px" }}
              >
                REDEEM WAKE KIT
              </h1>
            </div>
            <div className="col-12 main-info p-0">
              <img src={Wake} alt="" className="img-fluid" />
            </div>
            <div className="col-12 main-info p-0">
              <select
                style={{
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  width: "100%",
                }}
                className="form-select "
              >
                <option selected>Choose Size</option>
                <option value="1">Small</option>
                <option value="2">Medium</option>
                <option value="3">Large</option>
              </select>
            </div>

            <div className="col-12 main-info p-0">
              <button
                onClick={handleClick}
                className="redeemBtn mt-4 showselectorder"
              >
                Redeem WAKE Kit
              </button>
            </div>

            <div className={open ? "confirmpopup" : "confirmpopup2"}>
              <div className="orderbox3 ">
                <div
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "10px",
                    textAlign: "right",
                  }}
                >
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                <div className="innerscroll">
                  <div className="confirmpopup-content">
                    <div className="orderbox-content ob-bottom">
                      <p>
                        Please enter your shipping address to recieve the WAKE
                        branded kit shown below.
                      </p>
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Shipping Address"
                          style={{
                            width: "100%",
                            height: "60px",
                            borderRadius: "10px",
                            padding: "10px",
                            marginBottom: "10px",
                            border: "1px solid gray",
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Country"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="State"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="City"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <input
                            type="text"
                            placeholder="Zipcode"
                            style={{
                              width: "100%",
                              height: "60px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                              border: "1px solid gray",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="confirmpopup-content ob-bottom">
                    <div className="orderbox-content">
                      <b>Kit contents:</b>
                      <ul>
                        <li>Wake branded T-Shirt</li>
                        <li>Hooded Sweater</li>
                        <li>Water Bottle</li>
                        <li>Iris Headphones</li>
                        <li>12 Bottles of Wake functional mushrooms</li>
                        <li>Notebook & Pen</li>
                        <li>Eyeshade</li>
                      </ul>
                    </div>
                    <div className="confirmpopup-bottom">
                      <a
                        onClick={handleClick2}
                        className="redeemBtn mt-3 showconfirm"
                      >
                        Confirm
                      </a>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={open3 ? "orderbox" : "orderbox2"}>
              <div
                style={{
                  paddingTop: "5px",

                  textAlign: "right",
                }}
              >
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>

              <div>
                <button className="btn-close"></button>
                <div className="confirmpopup-content">
                  <h1 className="text-center d-block">THANK YOU</h1>
                  <p>
                    You will recieve a shipping notification once your kit is
                    shipped, and delivery is typically 1-2 weeks depending on
                    where in the world Carmen San Diego is.
                  </p>
                </div>
                <div>
                  <a onClick={(e) => handleNavigate(e)} className="redeemBtn mt-3">
                    Go to Redemption Store
                  </a>
                  <p style={{textAlign:"center", padding:"5px", margin:"10px"}}>or</p>
            <a onClick={handleOpenGift} className="mt-3 giftbtn">
              Send it as a gift to someone
            </a>
                </div>
              </div>
            </div>
            <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="text" placeholder='Enter Email'/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="text" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item.title.rendered} gift is sent.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
        </div>
        <div className={open ? "overloay1" : "overloay2"}></div>
        <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
          </div>
        </div>
      </main>
    </>
  );
}
