import React, { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
export default function Giftthanks() {
  const { component } = useParams();
  const navigate = useNavigate();
  const handleOpenGiftsent = (e) => {
    e.preventDefault();

    navigate("/redeem-nft", { replace: true });
  };
  return (
    <>
      <div className="nft_market thanks">
        <main className="thanks-c">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="thanks-msg">I Just gifted the gift of {component}. </div>
                <div className="shareit-text">Share it</div>
                <div className="shareit-icons">
                    <TwitterIcon style={{color:'#36B2B2'}} /><InstagramIcon  style={{color:'#36B2B2'}}/><FacebookRoundedIcon style={{color:'#36B2B2'}} />
                </div>
                <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Go to redemption store
            </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
