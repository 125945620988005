import React, { useState, useEffect } from "react";
import Cardimg from "../../assets/cardimg.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideoLabelOutlinedIcon from "@mui/icons-material/VideoLabelOutlined";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Eth from "../../assets/ETH.png";
import Metamask from "../../assets/metamask.png";
import { CartContext } from "../../Context";
import axiosClient from "../../helper";
import { useContext } from "react";
import Myaccount from "../../components/myaccount";

export default function Nft_market({
  connectWalletPressed,
  walletAddress,
  loading,
  disconnect,
  setDisconnect,
  setWallett,
  wallett,
  setSnackOpen,
  snackOpen,
  data2,
  open2,
  setOpen2,
}) {
  const [open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [back, setBack] = useState(false);
  const [token, setTokken] = useState("");
  const [buying, setBuying] = useState(false);
  const [hidehome, setHidehome] = useState(false);
  const [productloading, setLoading] = useState(false);
  const [products, setProduct] = useState(false);
  let navigate = useNavigate();

  const { data, loader } = useContext(CartContext);

  console.log("loader", loader);
  console.log("data", data);


  const showProduct = (x) => {
    //setBuying(x);
    setLoading(true);
    setHidehome(true);
    //console.log(hidehome)
    var vis = [];
    for(var i=0;i<x.variations.length;i++){
      vis.push(x.variations[i].id);
    };
    axiosClient()
    .get(`/wc/store/v1/products/?order=asc&type=variation&include=`+vis.join(','))
    .then((res) => {
      setProduct(res.data);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
  };

  const handleClick = (x) => {
    setBuying(x);
    if (wallett) {
      setOpen(false);
      setBack(true);
      setOpen2(true);
      setOpen(false);
      setBack(true);
    } else {
      setOpen(true);
      setBack(true);
      setSnackOpen(true);
    }
  };

  // useEffect(() => {
  //   if (wallett) {
  //     setOpen2(true);
  //     setBack(true);
  //   }
  // }, [wallett]);

  const handleClick3 = () => {
    setOpen3(true);
    setOpen2(false);
    setBack(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBack(false);
  };

  const handleClosee = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen(false);
    setBack(false);
  };
  const handleClose3 = () => {
    setOpen2(false);
    setOpen(false);
    setOpen3(false);
    setBack(false);
  };

  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/redeem-nft", { replace: true });
  };

  useEffect(() => {
    
    console.log("in useeffect", walletAddress);
    if (typeof window !== "undefined" && token === "") {
      const token = localStorage.getItem("token");
      setTokken(token);
    }
  }, [walletAddress]);

  const getWalletMessage = () => {
    if (loading) {
      return "Loading Wallet...";
    }
    setWallett(walletAddress);

    return walletAddress.length > 0 ? (
      String(walletAddress).substring(0, 6) +
        "..." +
        String(walletAddress).substring(38)
    ) : (
      <span>Connect Wallet</span>
    );
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClosee}
      >
        <CloseIcon fontSize="small" style={{ cursor: "pointer" }} />
      </IconButton>
    </React.Fragment>
  );

  const handleLogout = () => {
    console.log("walletAddress", walletAddress);
    window.localStorage.removeItem("token");
    setTokken("");
    setDisconnect(false);
    setWallett(false);
  };

  return (
    <div className="nft_market">
      <div
        style={{ justifyContent: "space-between", display: "flex" }}
        className="d-flex justify-content-between"
      >
        {hidehome && (<>
          
          <a onClick={() =>setHidehome(false)} >
            <ArrowBackIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        
        </>)}
        {!hidehome && (<>
        <Link to="/">
          <a>
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        </Link>
      </>)}
      <Myaccount wallett={wallett} setWallett={setWallett} walletAddress={walletAddress} loading={loading} connectWalletPressed={connectWalletPressed} />
       
      </div>
      <main className="nft_market_">
      {hidehome && (<>
        <div className="container">
          <div className="col-12 p-0">
            <h1 className="text-center d-block" style={{ fontWeight: "lighter" }}>
              WAKE NFT MARKET
            </h1>
            <h2 className="text-center d-block">{buying.name}</h2>
          </div>
          {productloading ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <>
                {products?.map((x, index) => (
                  <div
                    className="col-12 p-0 hi"
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() =>handleClick(x)}
                  >
                    <div
                      className="market-image2"
                    >
                      <img
                        src={`${x?.images[0].src}`}
                        alt=""
                        className={index === 0 ? "img-fluidss2" : "img-fluidss"}
                      />
                    </div>
                    <div className="inner-info">
                      <h3><span className="itemname">{x?.name} </span><span className="itemcolor">{x.variation?.replace('Color:','')}</span></h3>
                      <div className="d-flex align-items-center justify-content-between">
                        <a onClick={() =>handleClick(x)}  className="showselectorder">
                          BUY NOW
                        </a>
                        <span>
                          <img src={Eth} alt="" className="img-fluid" />{" "}
                          {x?.prices.price/100} ETH
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
         
      </>)}
      {!hidehome && (<>
        <div className="container">
          <div className="col-12 p-0">
            <h1 className="text-center d-block" style={{ fontWeight: "lighter" }}>
              WAKE NFT MARKET
            </h1>
            <h2 className="text-center d-block">Buy Artwork NFTs</h2>
          </div>
          <div
            className="row main-info"
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            {loader ? (
              <div style={{ marginTop: "50px", height: "100vh" }}>
                <CircularProgress size={30} />
              </div>
            ) : (
              <>
                {data?.map((x, index) => (
                  <div
                    className={index === 0 ? "col-12 p-0 hi" : "col-6 p-0"}
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() =>showProduct(x)}
                  >
                    <div
                      className={index === 0 ? "market-image2" : "market-image"}
                    >
                      <img
                        src={`${x?.images[0].src}`}
                        alt=""
                        className={index === 0 ? "img-fluidss2" : "img-fluidss"}
                      />
                    </div>
                    <div className="inner-info">
                      <h3><span className="itemname">{x?.name} </span><span className="itemcolors">{x.attributes?.map((xx,xindex)=>(<>{xx.terms.map((term,iterms)=>(<>{term.name} </>))}</>))}</span></h3>
                      <div className="d-flex align-items-center justify-content-between">
                        <a onClick={() =>showProduct(x)}  className="showselectorder">
                          VIEW & BUY
                        </a>
                        <span>
                          <img src={Eth} alt="" className="img-fluid" />{" "}
                          {x?.prices.price_range.min_amount/100} - {x?.prices.price_range.max_amount/100} ETH
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        </>)}
      </main>
      <div className={open ? "confirmpopupconnect" : "confirmpopupconnect2"}>
        <button onClick={handleClose} className="btn-close" />
        <div className="confirmpopup-content2">
          <img
            width="50px"
            height="40px"
            style={{ marginTop: "10px" }}
            src={Metamask}
            alt=""
          />
          <p className="metamask2">Connect Metamask Wallet</p>
        </div>
        <div className="confirmpopup-bottom">
          <Button
            className="redeemBtn mt-3 connectit"
            onClick={() => connectWalletPressed(true)}
            sx={{ p: 0 }}
            // className="wallet_btn"
          >
            {wallett ? (
              <>{getWalletMessage()}</>
            ) : (
              <p style={{ height: "7px" }}>Connect Wallet</p>
            )}
          </Button>
        </div>
      </div>

      <div className={open2 ? "orderbox" : "orderbox2"}>
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "right",
          }}
        >
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose2} />
        </div>
        <div className="confirmpopup-content w-100">
          <div className="orderbox-content ob-bottom w-100">
            <p style={{ fontSize: "20px", fontWeight: "lighter" }}>
              Please enter your email to confirm the purchase.
            </p>
            <div className="row mt-3">
              <div
                className="col-12"
                style={{ justifyContent: "center", display: "grid" }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{ marginBottom: "20px ", borderRadius: "15px" }}
                  placeholder="Email Address"
                />
              </div>
            </div>
          </div>
        </div>
        {buying && (<>
        <div className="confirmpopup-content ob-bottom w-100">
          <div className="orderbox-content w-100">
            <p>NFT Selected</p>
            <h1 className="d-block" style={{ fontWeight: "lighter" }}>
            {buying.name} {buying.variation?.replace('Color:','')}
            </h1>
          </div>
        </div>
        <div className="confirmpopup-content ob-bottom w-100">
          <div className="orderbox-content w-100">
            <p>Payment Method</p>
            <h1 className="d-block eth" style={{ fontWeight: "lighter" }}>
              {buying.prices.price/100} ETH
            </h1>
          </div>
        </div>
        </>)}
        <div className="confirmpopup-bottom">
          <a
            style={{ cursor: "pointer" }}
            onClick={handleClick3}
            className="redeemBtn mt-3 showconfirm"
          >
            Confirm
          </a>
        </div>
      </div>
      <div className={open3 ? "confirmpopup" : "confirmpopup2"}>
        <div
          style={{
            paddingTop: "5px",

            textAlign: "right",
          }}
        >
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose3} />
        </div>
        <div className="confirmpopup-content pt-5">
          <h1 className="text-center d-block" style={{ fontWeight: "lighter" }}>
            CONGRATULATIONS
          </h1>
          <p>
            You now own the
            <br />
            {buying && (<>{buying.name} - {buying.variation?.replace('Color:','')}</>)} NFT
            <br />
            Thank you for contributing to Wake Network community. You can access
            your exclusive rewards from the redemption store.{" "}
          </p>
        </div>
        <div className="confirmpopup-bottom">
          <a
            onClick={(e) => handleNavigate(e)}
            href="redeem-nft.html"
            className="redeemBtn mt-3"
          >
            Go to Redemption Store
          </a>
        </div>
      </div>
      {/* <Snackbar snackOpen={snackOpen} setSnackOpen={setSnackOpen} /> */}

      <div className={back ? "overloay1" : "overloay2"}></div>
    </div>
  );
}
