import React from "react";
import Feild from "../../assets/feild.png";
import { useNavigate } from "react-router-dom";

export default function Index() {
  let navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault();
    navigate("/retreat_01");
  };
  return (
    <>
      <main
        onClick={(e) => handleNavigate(e)}
        className="nft_market_"
        style={{ paddingBottom: "500px", cursor: "pointer" }}
      >
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
                REDEEM RETREAT
              </h1>
              <h2 className="text-center d-block">Choose Location</h2>
            </div>
          </div>

          <div
            style={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <img src={Feild} style={{ width: "100%" }} alt="" />
          </div>
        </div>
      </main>
    </>
  );
}
