import * as React from 'react';
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import AccessTime from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Select, { components } from 'react-select'
import { GifBoxOutlined, Sms, Wallet, EmailOutlined } from '@mui/icons-material';




function populate() {
  var slots = [];
  var hours, minutes, ampm;
  for(var i = 420; i <= 1320; i += 30){
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10){
          minutes = '0' + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours = hours % 12;
      if (hours === 0){
          hours = 12;
      }
      if (hours < 10){
        hours = '0'+hours;
    }      
      slots.push({
        value:i,
        label:hours + ':' + minutes + ' ' + ampm
      });
  }

  for(i=0;i<slots.length;i++){
    if(typeof slots[i+1] !=='undefined'){
      slots[i].label = slots[i].label+' - '+ slots[i+1].label;
      slots[i].value = slots[i].label;
    }
    
  }
  slots.pop();

  return slots;
}


const options = populate();

export default function Index({item}) {
  const [open, setOpen] = React.useState(false);
  const [gift, setGift] = React.useState(false);
  const [giftsent, setGiftsent] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [timeslots, setTimesloat] = React.useState(null);

  const CaretDownIcon = () => {
    return <AccessTime style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "35px" }} />;
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };  
  const Placeholder = props => {
    return (
      <components.Placeholder {...props}>
        Select Time
      </components.Placeholder>
    );
  };  

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenGift = () => {
    setOpen(false);
    setGift(true);
  };
  const handleOpenGiftsent = () => {
    navigate("/gift-thanks/"+item.title.rendered, { replace: true });    
    setOpen(false);
    setGift(false);
    setGiftsent(true);
  };  
  const handleCloseGift = () => {
    setGift(false);
  };
  const handleCloseGiftsent = () => {
    setGiftsent(false);
  };  
  let navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault();

    navigate("/redeem-nft", { replace: true });
  };
  const handleNavigateAccount = (e) => {
    e.preventDefault();
    console.log(item)
   // navigate("/gift-thanks/"+item.title.rendered, { replace: true });
  };
  return (
    <>

      <main className="nft_market_" style={{ paddingBottom: "100px" }}>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
              {item.title.rendered}
              </h1>
              <h2 className="text-center d-block">Select a date and timeslot</h2>
              <Grid container>
                    <Grid
            
                      item
                      xs={12}
                      className="redemption-item"
                    >
                  
                      <Grid style={{ width: "100%", height: "120px" }}>
                        <img
                          className="redemption-image"
                          src={`${item.x_featured_media_original}`}
                          alt=""
                        />
                      </Grid>
                      <Grid className="redemption-text">
            
                        <div className="redemption_store_text">
                         
                            {item.acf.heading.split('|').map(function(item) {
    return (
       <span>{item}</span>
    )
 })}
                      
                          
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
  
              <div style={{
                backgroundColor:'#fff',
                paddingBottom:'15px'
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={date}
        onChange={(newValue) => {
          setDate(newValue);
        }}
        minDate={new Date()}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

</div>
<div className='timepickerc'>
      
      <Select options={options}   onChange={(newValue) => {
        console.log(newValue)
          setTime(newValue);
        }}
        components={{ DropdownIndicator, Placeholder }} className="slotpicker" classNamePrefix='tsp' />
  </div>
            </div>
            <div className="col-12 main-info p-0">
              <a
                style={{ cursor: "pointer" }}
                onClick={handleOpen}
                className="redeemBtn mt-3 showconfirm"
              >
                Confirm
              </a>
            </div>
            {/*
            <div className="col-12 main-info p-0">
              <button
                type="button"
                className="modalBtn"
                style={{ justifyContent: "space-between", display: "flex" }}
                onClick={handleOpen}
              >
                Choose Strain
                <ArrowForwardIosIcon style={{ color: "#89858B" }} />
              </button>
            </div>
            <div className="col-12 p-0">
              <p
                className="text-center"
                style={{ fontSize: "20px", fontWeight: "lighter" }}
              >
                Not Sure?
              </p>
            </div>
            <div className="col-12 p-0 mt-3 mb-3">
              <a
                onClick={(e) => handleNavigate(e)}
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                }}
                className="modalBtn"
              >
                Choose For Me
                <ArrowForwardIosIcon style={{ color: "#89858B" }} />
              </a>
            </div> */}
          </div>
        </div>
      </main>

      <div className={open ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            {/* <button onClick={handleClose} className="btn-close" /> */}
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item.title.rendered} is booked. You will receive an email with further details.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigate} className="redeemBtn mt-3">
              go to Redemptions
            </a>
            <p style={{textAlign:"center", padding:"5px", margin:"10px"}}>or</p>
            <a onClick={handleOpenGift} className="mt-3 giftbtn">
              Send it as a gift to someone
            </a>
          </div>
        </div>
        <div className={gift ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGift} />
          </div>
          <div className="confirmpopup-content">
          
            <p>
            Please enter one of the fields with the recipient information to 
send your redemption as a gift.
            </p>
            <div className="forminput">
              <label><EmailOutlined  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Email</label>
              <input type="text" placeholder='Enter Email'/>
            </div>
            <div className="forminput">
              <label><Sms  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }}/>  SMS</label>
              <input type="text" placeholder='Enter Phone Numner'/>
            </div>
            <div className="forminput">
              <label><Wallet  style={{ color: "rgba(0, 0, 0, 1)", fontSize: "16px" }} /> Address</label>
              <input type="text" placeholder='Enter Wallet Address'/>
            </div>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleOpenGiftsent} className="redeemBtn mt-3">
                Confirm & Send the Gift
            </a>

          </div>
        </div>
        <div className={giftsent ? "confirmpopup" : "confirmpopup2"}>
          <div style={{ justifyContent: "right", display: "flex" }}>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleCloseGiftsent} />
          </div>
          <div className="confirmpopup-content">
            <h1 className="text-center d-block">THANK YOU</h1>
            <p>
            Your {item.title.rendered} gift is sent.
            </p>
          </div>
          <div className="confirmpopup-bottom">
            <a onClick={handleNavigateAccount} className="redeemBtn mt-3">
              My Account
            </a>
          </div>
        </div>
        <div className={open ? "overloay1" : "overloay2"}></div>
        <div className={gift ? "overloay1" : "overloay2"}></div>
        <div className={giftsent ? "overloay1" : "overloay2"}></div>
    </>
  );
}
