import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import axiosClient from "./helper";
export const CartContext = createContext();

export function CartProvider({ children }) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState();
  const [store, setStore] = useState();

  useEffect(() => {
    setLoader(true);

    axiosClient()
      .get(`/wc/store/v1/products`)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });

    axiosClient()
      .get(`/wp/v2/redemption?order=asc`)
      .then((res) => {
        setStore(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  return (
    <CartContext.Provider value={{ data, loader, store }}>
      {children}
    </CartContext.Provider>
  );
}

export default createContext;
