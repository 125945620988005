import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useParams, useNavigate } from "react-router-dom";
import { CartContext } from "../../Context";
import { useContext } from "react";
import Myaccount from "../../components/myaccount";
export default function Index({ wallett, setWallett, walletAddress, loadingWallet, connectWalletPressed }) {
  console.log('loadingWallet',loadingWallet)
  const navigate = useNavigate();
  const { store, loader } = useContext(CartContext);


  return (
    <div className="nft_market">
      <div className="d-flex justify-content-between">
        <Link to="/">
          <a>
            <HomeOutlinedIcon style={{ color: "gray", fontSize: "35px" }} />
          </a>
        </Link>
        <Myaccount wallett={wallett} setWallett={setWallett} walletAddress={walletAddress} loading={loadingWallet} connectWalletPressed={connectWalletPressed} />
       
      </div>
      <main className="nft_market_">
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <h1
                className="text-center d-block"
                style={{ fontWeight: "lighter" }}
              >
                REDEMPTION STORE
              </h1>
            </div>
          </div>

          <div className="row  mt-4">
            {loader ? (
              <div
                style={{
                  height: "100vh",
                  paddingTop: "270px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {store?.map((x) => (
                  <Grid container>
                    <Grid
                      onClick={() => navigate(`/radeem_grow/${x.slug}`)}
                      item
                      xs={12}
                      className="redemption-item"
                    >
                  
                      <Grid style={{ width: "100%", height: "114px" }}>
                        <img
                          className="redemption-image"
                          src={`${x?.x_featured_media_original}`}
                          alt=""
                        />
                      </Grid>
                      <Grid className="redemption-text">
                      <span className="rnftavailable">Available ({x?.acf.available})</span>
                        <div className="redemption_store_text">
                         
                            {x?.acf.heading.split('|').map(function(item) {
    return (
       <span>{item}</span>
    )
 })}
                      
                          
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
