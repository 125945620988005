import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Landing from "./pages/landing/index";
import Dashboard from "./pages/dashboard/index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NftMarket from "./pages/nft_market/nft_market";
import RadeemNft from "./pages/redeem-nft";
import RadeemGrow from "./pages/radeem_grow";
import Penisenvy from "./pages/penisenvy";
import PenisenvyVideo from "./pages/penisenvy_video";
import MuiAlert from "@mui/material/Alert";
import { connectWallet } from "./Integrations/Wallet";
import axios from "axios";
import { CartProvider } from "./Context";
import Retreat from "./pages/retreat_01";
import Vitals from "./pages/vitals";
import Giftthanks from "./pages/giftthanks";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function App() {
  const [walletAddress, setWallet] = useState("");
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [showError, setShowError] = useState({ show: false, message: "" });
  const [status, setStatus] = useState("");
  const [open2, setOpen2] = useState(false);

  const [data2, setData2] = useState();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState();
  const [snackType, setSnackType] = useState();
  const [disconnect, setDisconnect] = useState(false);
  const [wallett, setWallett] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const connectWalletPressed = async (hello) => {
    const walletResponse = await connectWallet(window);
    if (walletResponse.error) {
      handleWalletError(walletResponse.error);
    }else if(walletResponse.status==='METAMASK_NOT_INSTALLED'){
      setShowError({ show: true, message:'METAMAST Not Installed' });
    }
    addWalletListener();
    
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    //setSnackOpen(false);
   
    localStorage.setItem("token", walletResponse.address);
    if (walletResponse.address) {
      setWallett(true);
      setDisconnect(true);
      setOpen2(true);
    }

    if (hello) {
      setOpen2(true);
    }
  };
  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        } else {
          setWallet("");
          setStatus("NOT_CONNECTED");
        }
      });
      window.ethereum.on("chainChanged", (chainId) => {
        handleChainChanged(chainId);
      });
    } else {
      setStatus("METAMASK_NOT_INSTALLED");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowError({ show: false, message });
  };

  const handleChainChanged = (_chainId) => {
    // We recommend reloading the page, unless you must do otherwise
    window.location.reload(false);
  };
  const handleWalletError = (error) => {
    const code = error.code;
    const message = error.message;

    if (message === "Already processing eth_requestAccounts. Please wait.") {
      const content = "Please confirm on your metamask extension";
      setShowError({ show: true, message: content });
    } else {
      setShowError({ show: true, message });
    }
  };

  useEffect(() => {
    if (walletAddress) {
      axios
        .post(`https://presale.wake.net/?mod=user&do=add`, {
          walletAddress: walletAddress,
        })
        .then((res) => {
          setData2(res);
          setMessage(res.data.message);
          if ((res.data.message, res.data.success)) {
            setOpen(true);
            setSnackType(true);
          } else {
            setSnackType(false);
          }
        });
    }
  }, [walletAddress]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" style={{ cursor: "pointer" }} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing wallett={wallett} />} />
            <Route path="/dashboard" element={<Dashboard                   
                    setWallett={setWallett}
                    walletAddress={walletAddress}
                    wallett={wallett}
                    loading={loadingWallet} 
                    connectWalletPressed={connectWalletPressed}
                    />} />
            <Route
              path="/nft-market"
              element={
                <CartProvider>
                  <NftMarket
                    setSnackOpen={setSnackOpen}
                    snackOpen={snackOpen}
                    connectWalletPressed={connectWalletPressed}
                    walletAddress={walletAddress}
                    loading={loadingWallet}
                    disconnect={disconnect}
                    setDisconnect={setDisconnect}
                    setWallett={setWallett}
                    wallett={wallett}
                    data2={data2}
                    setOpen2={setOpen2}
                    open2={open2}
                  />
                </CartProvider>
              }
            />

            <Route
              path="/redeem-nft"
              element={
                <CartProvider>
                  <RadeemNft
                    setWallett={setWallett}
                    walletAddress={walletAddress}
                    wallett={wallett}
                    loadingWallet={loadingWallet}
                    connectWalletPressed={connectWalletPressed}
                  />
                </CartProvider>
              }
            />
            <Route
              path="/radeem_grow/:component"
              element={
                <RadeemGrow
                  setWallett={setWallett}
                  walletAddress={walletAddress}
                  wallett={wallett}
                  loading={loadingWallet}
                  connectWalletPressed={connectWalletPressed}
                />
              }
            />
            <Route
              path="/penisenvy"
              element={
                <Penisenvy
                  setWallett={setWallett}
                  walletAddress={walletAddress}
                  wallett={wallett}
                  loading={loadingWallet}
                  connectWalletPressed={connectWalletPressed}
                />
              }
            />
            <Route
              path="/penisenvy_Video"
              element={
                <PenisenvyVideo
                  setWallett={setWallett}
                  walletAddress={walletAddress}
                  wallett={wallett}
                  loading={loadingWallet}
                  connectWalletPressed={connectWalletPressed}
                />
              }
            />

            <Route
              path="/retreat_01"
              element={
                <Retreat
                  setWallett={setWallett}
                  walletAddress={walletAddress}
                  wallett={wallett}
                  loading={loadingWallet}
                  connectWalletPressed={connectWalletPressed}
                />
              }
            />
            <Route
              path="/vitals"
              element={
                <Vitals
                  setWallett={setWallett}
                  walletAddress={walletAddress}
                  wallett={wallett}
                  loading={loadingWallet}
                  connectWalletPressed={connectWalletPressed}
                />
              }
            />
            <Route
              path="/gift-thanks/:component"
              element={
                <Giftthanks />
              }
            />
          </Routes>
        </BrowserRouter>

        <div>
          <Snackbar
            open={showError.show}
            autoHideDuration={6000}
            onClose={handleErrorClose}
            action={action}
          >
            <Alert
              onClose={handleErrorClose}
              severity={snackType ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {showError.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
}

export default App;
