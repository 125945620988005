import React, { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";



export default function Myaccount({  wallett, setWallett, walletAddress, loading, connectWalletPressed }) {
    const [open, setOpen] = useState(false);  
    const getWalletMessage = () => {
        if (loading) {
          return "Loading Wallet...";
        }
  
        setWallett(walletAddress);
    
        return walletAddress.length > 0 ? (
          String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        );
      }; 
      const showDropdown = () =>{
      
        setOpen(!open);
      }

      return (
        <><div className="dropdown">
          {!wallett && (
        <Button
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            borderRadius: "40px",
            padding: "10px",
            backgroundColor: "#404040",
            color: "#fff",
            fontSize: "13px",
            textTransform: "capitalize",
            cursor: "pointer",
           
          }}
          onClick={() => connectWalletPressed(true)}
        >
          {wallett ? <>Welcome {getWalletMessage()}</> : 'Connect Wallet'}
        </Button>
        )}
         {wallett && (
          <Button
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            borderRadius: "40px",
            padding: "10px",
            backgroundColor: "#404040",
            color: "#fff",
            fontSize: "13px",
            textTransform: "capitalize",
            cursor: "pointer",
           
          }}
          onClick={() => showDropdown(true)}
        >
          {wallett ? <>Welcome {getWalletMessage()}</> : 'Connect Wallet'}
        </Button>

         )}
         {open && (
        <div className="dropdown-menu open" aria-labelledby="dropdownMenuButton">
          <Link to="/dashboard" className="dropdown-item">
            My NFTS
          </Link>
          <Link to="/" className="dropdown-item">
            Logout
          </Link>
        </div>
        )}
      </div></>
      )
}